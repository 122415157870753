<template>
  <div style="margin: -15px -15px">
    <a-spin :spinning="spinning">
      <div class="magazine">
        <!--  标题搜索-->
        <div class="searchTable">
          年份: &nbsp;<a-date-picker
            v-model="yearQuarter"
            :disabled-date="disabledDate"
            :open='yearShowOne'
            format='YYYY'
            mode="year"
            placeholder="请选择"
            style="width: 10%;margin-right: 15px"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
          标题:&nbsp;
          <a-input v-model="titleValue" placeholder="请输入" size="default" style="width: 210px;margin-right: 40px"/>
          状态: &nbsp;<a-radio-group default-value="1" style="margin-right: 40px" @change="callback($event)">
          <a-radio-button value="1">
            已发布
          </a-radio-button>
          <a-radio-button value="2">
            已置顶
          </a-radio-button>
          <a-radio-button value="3">
            未发布
          </a-radio-button>
        </a-radio-group>
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button style="margin-left: 10px" @click="searchCancel">重置</a-button>
        </div>

        <div class="magazine_list">
          <!--    杂志列表-->
          <!--   新增按钮-->
          <div style="display: flex;width:100%;justify-content: space-between">
            <div style="display: flex; width: 150px;justify-content: space-between">
              <a-button v-model="spinning" style="margin-bottom: 10px" type="primary" @click="()=>{this.visible = true;this.imageStyleType = '使用模板';$nextTick(() => {imageStyleChange('使用模板', 'add')})}">
              新增
              </a-button>
              <a-popconfirm
                cancel-text="否"
                ok-text="是"
                title="是否删除这些数据?"
                @confirm="delMagazine"
            >
              <a-button>
                删除
              </a-button>
              </a-popconfirm>
            </div>
            <div>
              <a-popconfirm
                cancel-text="否"
                ok-text="是"
                title="确认发布吗?"
                @confirm="isPublish(0)"
              >
                <a-button style="margin-left:20px;margin-right:20px"  v-if="topBtn === false">批量发布</a-button>
              </a-popconfirm>
              <a-popconfirm
                cancel-text="否"
                ok-text="是"
                title="确认下架吗?"
                @confirm="isPublish(1)"
              >
                <a-button style="margin-left:20px;margin-right:20px"  v-if="topBtn === true">批量下架</a-button>
              </a-popconfirm>
            </div>
          </div>
          <a-table :columns="columns" :data-source="MagazineTable.rows" :pagination="false"
                   :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                   :scroll="{ x: 1500, y: 600}"
                   rowKey="id"
                   size="middle">
            <!--        序号-->
            <template slot="index" slot-scope="text,row,index">
              {{ index + ((pagination.current - 1) * pagination.pageSize) + 1 }}
            </template>
            <span slot="table_title" slot-scope="text,record">
       <a-tag v-if="record.is_top === 1" color="#F5222D" style="margin-right: 0%">
        置顶
      </a-tag>
      <a :href="record.url" target="_blank">
      {{ record.title }}
    </a>
    </span>
            <span slot="is_show" slot-scope="text, record" style="display: flex;justify-content: space-between;">
        <a-popconfirm
            cancel-text="取消"
            ok-text="确定"
            title="确定执行操作？"
            @confirm="change_show(record.id,record.is_show === 1? 0 : 1)"
        >
          <a :disabled="record.recommendState && record.is_show !== 1">{{record.is_show === 1 ? '上架':'下架'}}</a>
        </a-popconfirm>
        <a-popconfirm
            cancel-text="否"
            ok-text="是"
            title="是否置顶?"
            @confirm="topChange(record.id,record.is_top === 1?0:1)"
        >
          <a-button v-show="topBtn" size="small" type="link"
                    v-html="record.is_top === 0 ? '置顶':'取消置顶'">{{ record.is_top }}</a-button>
          </a-popconfirm>
      <a @click="updateOpen(record.id)">修改</a>
      <a @click="promotions(record)">推广</a>
              <!--      <a-button  style="margin-left: 10px" size="small" type="primary" @click="update(record.id)">修改</a-button>-->
    </span>
          </a-table>
          <div style="margin-top: 30px;padding-left: 20px">
            <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{ pagination.current }} / {{
                Math.ceil(pagination.total / pagination.pageSize)
              }} 页</span>
            <a-pagination
                v-model="pagination.current"
                :page-size="pagination.pageSize"
                :page-size-options="pageSizeOptions"
                :total="pagination.total"
                show-quick-jumper
                show-size-changer
                style="float: right;margin-bottom: 40px"
                @change="tableChange"
                @showSizeChange="tableChange"
            >
            </a-pagination>
          </div>
        </div>

        <!--    =================弹框=======================-->
        <!--    新增事件-->
        <a-drawer
            :confirm-loading="confirmLoading"
            :visible="visible"
            title="新增"
            width="700px"
            @close="handleCancel"
        >
          <a-form-model style="margin-bottom: 30px" ref="addItem" :label-col="labelCol" :model="AddForm" :rules="rules" :wrapper-col="wrapperCol">
            <a-form-model-item label="标题" prop="title">
              <a-input v-model="AddForm.title" placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="describe">
              <a-input v-model="AddForm.describe" placeholder="请输入" type="textarea"/>
            </a-form-model-item>
            <a-form-model-item label="显示日期" prop="show_date">
              <a-month-picker
                  placeholder="请选择时间"
                  style="width: 100%;"
                  @change="panelChange"
                  v-model="AddForm.show_date"
              />
              <div>
                {{AddForm.show_date?AddFormShowDate:null}}
              </div>
            </a-form-model-item>
            <a-form-model-item label="作者" prop="author">
              <a-input v-model="AddForm.author" placeholder="请输入"/>
            </a-form-model-item>
            <a-form-model-item label="杂志名称" prop="name">
              <a-input v-model="AddForm.name" placeholder="请输入"/>
            </a-form-model-item>
            <a-form-model-item label="封面图" prop="image">
              <div>
                  <a-select
                      v-model:value="imageStyleType"
                      style="width: 120px"
                      @change="imageStyleChange($event,'add')"
                  >
                    <a-select-option value="使用模板">使用模板</a-select-option>
                    <a-select-option value="其他">其他</a-select-option>
                  </a-select>
              </div>
              <div v-show="imageStyleType === '使用模板'" style="">
                <img :style="{width: '127px',height:'170px',marginRight: '5px',border: '2px #d1d1d1 dashed',cursor: 'pointer'}" :data-url="item.url" @click="imageMuBanClick(item.url,'add')" v-for="item in imageMuBanList" ref="addImageList" :src="item.url"/>
              </div>
              <template v-if="imageStyleType !== '使用模板'">
                <div class="uploadImg">
                  <input class="upload_img" type="file" @change="upload" ref="upload_img" />
                </div>
                <div class="upload_image">
                  <img v-if="AddForm.image" style="width: 127px;height:180px;" :src="AddForm.image" alt="form.image" ref="addImageType" />
                </div>
                <div style="float: left;width: 330px;font-size: 12px;">注：建议尺寸500*707px，宽度不能超过500，大小不超过1M</div>
              </template>
            </a-form-model-item>
            <a-form-model-item label="跳转链接" prop="url">
              <a-input v-model="AddForm.url" placeholder="请输入" type="textarea"/>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input v-model="AddForm.remark" placeholder="请输入"/>
            </a-form-model-item>
          </a-form-model>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="handleCancel">
              取消
            </a-button>
            <a-button type="primary" @click="addOk">
              提交
            </a-button>
            <a-button type="primary" @click="addOkAndkeep" :style="{ marginLeft: '8px' }">
              提交并继续
            </a-button>
          </div>
        </a-drawer>
        <!--    修改事件-->
        <a-drawer :visible="Update" title="修改" width="700px" @close="editClose">
          <a-form-model
              style="margin-bottom: 30px"
              ref="editItem"
              :label-col="labelCol" :model="UpdateForm" :rules="rulesEdit" :wrapper-col="wrapperCol">
            <a-form-model-item label="标题" prop="title">
              <a-input v-model="UpdateForm.title" placeholder="请输入"/>
            </a-form-model-item>
            <a-form-model-item label="描述" prop="describe">
              <a-input v-model="UpdateForm.describe" placeholder="请输入" type="textarea"/>
            </a-form-model-item>
            <a-form-model-item label="显示日期" prop="show_date">
              <a-month-picker
                  @change="panelChangeRest"
                  placeholder="请选择时间"
                  style="width: 100%;"
                  v-model="UpdateForm.show_date"
              />
              <div>
                {{UpdateForm.show_date?UpdateFormShowDate:null}}
              </div>
            </a-form-model-item>
            <a-form-model-item label="封面图" prop="image">
              <div>
                <a-select
                    v-model:value="imageStyleType"
                    style="width: 120px"
                    @change="imageStyleChange($event,'edit')"
                >
                  <a-select-option value="使用模板">使用模板</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </div>
              <div v-show="imageStyleType === '使用模板'">
                <img :style="{width: '127px',height:'170px',marginRight: '5px',border: '2px #d1d1d1 dashed',cursor: 'pointer'}" :data-url="item.url" @click="imageMuBanClick(item.url,'edit')" v-for="item in imageMuBanList" ref="editImageList" :src="item.url"/>
              </div>
              <template v-if="imageStyleType !== '使用模板'">
                <div class="uploadImg">
                  <input class="upload_img" type="file" @change="editImg" ref="editImg" />
                </div>
                <div class="upload_image">
                  <img v-if="UpdateForm.image" style="width: 127px;height:180px;" :src="UpdateForm.image" alt="" />
                </div>
                <div style="float:left;width: 330px;font-size: 12px;">注：建议尺寸500*707px，宽度不能超过500，大小不超过1M</div>
              </template>
            </a-form-model-item>
            <a-form-model-item label="作者" prop="author">
              <a-input v-model="UpdateForm.author" placeholder="请输入"/>
            </a-form-model-item>
            <a-form-model-item label="杂志名称" prop="name">
              <a-input v-model="UpdateForm.name" placeholder="请输入"/>
            </a-form-model-item>
            <a-form-model-item label="跳转链接" prop="url">
              <a-input v-model="UpdateForm.url" placeholder="请输入" type="textarea"/>
            </a-form-model-item>
            <a-form-model-item label="备注">
              <a-input v-model="UpdateForm.remark" placeholder="请输入"/>
            </a-form-model-item>
          </a-form-model>
          <!--      <template slot="footer">-->
          <!--        <a-button @click.stop="()=>{Update=false}">取消</a-button>-->
          <!--        <a-button type="primary" @click="editOk" v-model="spinning">确定</a-button>-->
          <!--      </template>-->
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editClose">
              取消
            </a-button>
            <a-button type="primary" @click="editOk">
              提交
            </a-button>
          </div>
        </a-drawer>
        <!--    预览弹框-->
        <!--  <a-modal v-model="Preview" title="详情"  width="700px"  :footer="null"  >-->
        <!--    <a-descriptions title="" :column="1">-->
        <!--      <a-descriptions-item label="标题">-->
        <!--        {{PreviewForm.title}}-->
        <!--      </a-descriptions-item>-->
        <!--      <a-descriptions-item label="描述">-->
        <!--        {{PreviewForm.describe}}-->
        <!--      </a-descriptions-item>-->
        <!--      <a-descriptions-item label="显示日期">-->
        <!--        {{ PreviewForm.show_date}}-->
        <!--      </a-descriptions-item>-->
        <!--      <a-descriptions-item label="上传图片">-->
        <!--        <img style="width: 200px;height: 200px" :src="PreviewForm.image" alt="">-->
        <!--      </a-descriptions-item>-->
        <!--      <a-descriptions-item label="跳转链接">-->
        <!--        {{PreviewForm.url}}-->
        <!--      </a-descriptions-item>-->
        <!--      <a-descriptions-item label="备注">-->
        <!--        {{PreviewForm.remark}}-->
        <!--      </a-descriptions-item>-->
        <!--    </a-descriptions>-->
        <!--  </a-modal>-->


        <a-drawer
            :body-style="{ paddingBottom: '80px' }"
            :visible="promotionsVisible"
            :width="500"
            title="推广"
            @close="promotionsClose"
        >
          <a-spin :spinning="promotionSpin">
            <div style="margin:20px">
              <a-table :columns="appColumns" :data-source="appData" :pagination="false" :row-key="record => record.code"
                bordered>
                <span slot="is_open" slot-scope="text, record">
                  <a-switch v-model="record.is_open" checked-children="是" default-checked
                            un-checked-children="否" @click="AppListSwitch(record.is_open,record)"/>
                </span>
              </a-table>
            </div>
            <div style="margin:20px">
              <a-table :columns="communityColumns" :data-source="communityData" :pagination="false" :row-key="record => record.id" bordered>
                <span slot="is_open" slot-scope="text, record">
                   <a-switch v-model="record.is_open" checked-children="是" default-checked
                             un-checked-children="否" @click="communityListSwitch(record.is_open,record)"/>
                </span>
              </a-table>
            </div>
            <!-- <div style="margin:20px">
              <a-table :columns="newAppColumns" :data-source="newAppData" :pagination="false" :row-key="record => record.code"
                bordered>
                <span slot="is_open" slot-scope="text, record">
                  <a-switch v-model="record.is_open" checked-children="是" default-checked
                            un-checked-children="否" @click="AppListSwitch(record.is_open,record)"/>
                </span>
              </a-table>
            </div> -->
            <div style="margin:20px">
              <a-table :pagination="false" :row-key="record => record.id" :columns="newAppColumns" :data-source="newAppData" bordered>
                <span slot="is_open" slot-scope="text, record">
                  <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
                </span>
              </a-table>
            </div>
         
          </a-spin>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
              关闭
            </a-button>
          </div>
        </a-drawer>


      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  delMagazines,
  getMagazine,
  getMagazineContent,
  postUpload,
  putEdit,
  putMagazine,
  putBatch
} from '@/service/magazin_api'
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
import {update} from '@/utils/update'
import moment from "moment/moment";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import {
  delAppPromotion, getAppPromotion, getColumnVodsList,
  getcommunityPromotion,
  postAppPromotion,
  postcommunityPromotion
} from "@/service/MedicalConference_y";
import {getAppContent} from "@/service/homePageFeatures_api";


export default {
  name: "Magazine",
  data() {
    let addImageValid = (rule, value, callback) => { // 自定义校验规则-新增图片
      if (this.AddForm.image) {
        callback();
      }else {
        callback(new Error('请上传封面图'));
      }
    };
    let editImageValid = (rule, value, callback) => { // 自定义校验规则-新增图片
      if (this.UpdateForm.image) {
        callback();
      }else {
        callback(new Error('请上传封面图'));
      }
    };
    return {
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      locale,
      show: 0,
      isTop: 0,
      user_id: '',
      topBtn: true,
      spinning: false,
      communityCode: '',
      appData: [],
      newAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      promotionId: null,
      subContentType: null,

      communityData: [
        {
          id: 1,
          title: '首页',
          is_open: false
        }
      ],
      communityColumns: [
        {
          title: '社区',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      promotionsVisible: false,
      appColumns: [
        {
          title: 'APP首页',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      newAppColumns: [
        {
          title: '新版APP首页（未上线）',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: {customRender: 'is_open'},
        },
      ],
      promotionSpin: false,

      selectedRowKeys: [],
      //获取输入框内容
      titleValue: '',
      //表单验证
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'}],
        url: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ],
        show_date: [
          {required: true, message: '请输入日期', trigger: 'change'}
        ],
        describe: [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ],
        image:[
          { validator:addImageValid,required: true, trigger: 'blur' }
        ],
        author: [
          {required: true, message: '请输入作者', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入杂志名称', trigger: 'blur'}
        ]
      },
      rulesEdit: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'}],
        url: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ],
        show_date: [
          {required: true, message: '请输入日期', trigger: 'change'}
        ],
        describe: [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ],
        image:[
          { validator:editImageValid,required: true, trigger: 'blur' }
        ],
        author: [
          {required: true, message: '请输入作者', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入杂志名称', trigger: 'blur'}
        ]
      },
      // 添加,修改,预览弹框的隐藏
      visible: false,
      Update: false,
      Preview: false,
      confirmLoading: false,
      // 医学杂志数据
      MagazineTable: [],
      // 分页器
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      count: 2,
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      // 添加表单
      AddForm: {
        title: '',
        describe: '',
        url: '',
        show_date: '',
        remark: '',
        image: '',
        is_show:0
      },
      imageStyleType: '使用模板',
      AddFormShowDate:null,
      UpdateFormShowDate:null,
      // 修改表单
      UpdateForm: {
        image: ''
      },
      PreviewForm: {},
      // 表格表头
      columns: [
        {
          title: '序号',
          fixed: 'left',
          align: 'center',
          width: 60,
          scopedSlots: {customRender: 'index'}
        },
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   width: '5%',
        // },
        {
          title: '标题',
          width: '25%',
          scopedSlots: {customRender: 'table_title'}
        },
        {
          title: '作者',
          width: '10%',
          dataIndex: 'author',
          ellipsis: true,
        },
        {
          title: '杂志名称',
          width: '17%',
          dataIndex: 'name',
          ellipsis: true,
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        // },
        {
          title: '显示日期',
          // width: '15%',
          align: 'center',
          dataIndex: 'show_date',
        },
        {
          title: '更新日期',
          // width: '15%',
          align: 'center',
          dataIndex: 'alter_time',
          ellipsis: true,
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: {customRender: 'is_show'},
        },
      ],
      yearQuarter: null,
      yearShowOne: false,
      imageMuBanList: []
    }
  },
  async created() {
    await this.$store.dispatch('setManageHeaderTitle', "医学杂志管理")
    await this.getMagazineList()
    //模板图片
    let imageList = [
        // 'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/2b87db7e-95b4-4f25-bc27-9aff2c238b1d.png',
        // 'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/e5ed545d-de80-4ae7-a611-811c2813d5b9.png',
        // 'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/8775683a-5459-4e9a-9a5f-ce1c16d39eaa.jpg',
        'https://image.yunicu.com/other/m1.png',
        'https://image.yunicu.com/other/m2.png',
        'https://image.yunicu.com/other/m3.png',
        "https://image.yunicu.com/other/m4.png",
        "https://image.yunicu.com/other/m5.png",
        "https://image.yunicu.com/other/m6.png",
        "https://image.yunicu.com/other/m7.png",
        "https://image.yunicu.com/other/m8.png",
        "https://image.yunicu.com/other/m9.png",
        "https://image.yunicu.com/other/m10.png",
        "https://image.yunicu.com/other/m11.png",
        "https://image.yunicu.com/other/m12.png",
        "https://image.yunicu.com/other/m13.png",
    ]
    for (let i = 0; i <imageList.length; i++) {
      // let imageB64 = await this.getBase64(imageList[i])
      // console.log("base",imageB64)
      this.imageMuBanList.push({
        // be64: imageB64,
        url: imageList[i]
      })
    }
  },
  methods: {
    imageMuBanClick (url,type) { //点击模板图片
      let refs, aform
      if (type === 'add') { // 判断新增
        this.AddForm.image = url
        refs = this.$refs.addImageList
        aform = 'AddForm'
      }else {  // 判断修改
        this.UpdateForm.image = url
        refs = this.$refs.editImageList
        aform = 'UpdateForm'
      }
      refs.map(item => { //找出受影响的img
        if (item.dataset.url === this[aform].image) { // 受影响，变实线边框
          item.style.border = '2px solid #40A9FF'
        } else {
          item.style.border = '2px #d1d1d1 dashed' // 不受影响，变虚线边框
        }
      })
    },
    getBase64(imgUrl) {
      return new Promise((resolve, reject) => {
        if (!imgUrl) {
          reject('请传入imgUrl内容')
        }
        if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(imgUrl)) {
          window.URL = window.URL || window.webkitURL
          var xhr = new XMLHttpRequest()
          xhr.open('get', imgUrl, true)
          xhr.responseType = 'blob'
          // xhr.Referer = 'https://yunicu.com'
          // xhr.setRequestHeader('Referer', 'https://yun manage.yunicu.com');
          xhr.onload = function() {
            if (this.status == 200) {
              // 得到一个blob对象
              var blob = this.response
              const oFileReader = new FileReader()
              oFileReader.onloadend = function(e) {
                const base64 = e.target.result
                resolve(base64 || '')
              }
              oFileReader.readAsDataURL(blob)
            }
          }
          xhr.send()
        } else {
          // 非图片地址
          reject('非(png/jpe?g/gif/svg等)图片地址')
        }
      })
    },
    imageStyleChange (value,type) { // 封面图样式切换
      if (type === 'add' && value === '使用模板') {
        this.imageMuBanClick(this.AddForm.image,type)
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true
      }
    },
    panelChange(value){
      this.AddFormShowDate = moment(this.AddForm.show_date).format('YYYY年M月')+'刊'
    },
    panelChangeRest(){
      this.UpdateFormShowDate = moment(this.UpdateForm.show_date).format('YYYY年M月')+'刊'
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearQuarter = value;
      this.yearShowOne = false;
    },
    disabledDate(current) { // 限制日期选择
      return current && moment(current).format('YYYY') > moment().format('YYYY')
    },
    //获取杂志接口
    async getMagazineList(title, pageNumber) {
      pageNumber = this.pagination.current
      if (pageNumber === 0) {
        return
      }
      this.spinning = true
      const is_show = this.show
      let is_top = this.isTop
      if (is_show === 0) {
        is_top = undefined
      }
      let time
      if (this.yearQuarter === undefined || this.yearQuarter === null) {
        time = ''
      } else {
        time = moment(this.yearQuarter).format('YYYY')
      }
      const result = await getMagazine(title, is_show, is_top, pageNumber, time, this.pagination.pageSize)
      if (result.code === 0) {
        const MagazineTable = result.data
        this.pagination.total = MagazineTable.count
        this.MagazineTable = MagazineTable
      } else {
        this.$message.error("获取错误，请联系管理员~" + result.message);
      }
      this.spinning = false
    },
    callback(e) {
      let activeKey = e.target.value
      switch (activeKey) {
        case '1':
          this.show = 0
          this.isTop = 0
          this.topBtn = true
          this.pagination.current = 1
          this.getMagazineList(this.titleValue)
          break;
        case '2':
          this.show = ''
          this.isTop = 1
          this.topBtn = true
          this.pagination.current = 1
          this.getMagazineList(this.titleValue)
          break;
        case '3':
          this.show = 1
          this.isTop = ''
          this.topBtn = false
          this.pagination.current = 1
          this.getMagazineList(this.titleValue)
          break;
        default:
          break;
      }
    },

    // 搜索框
    async onSearch() {
      const title = this.titleValue
      await this.getMagazineList(title)
      this.pagination.current = 1
    },
    // 搜索重置
    searchCancel() {
      this.titleValue = ''
      this.yearQuarter = null
      this.pagination = {
        //配置分页
        pageSize: 10,
        //总数
        total: 0,
        //默认的当前页数
        current: 1
      }
      this.getMagazineList('')
    },
    // 分页器 参数   回调
    async tableChange(pageNumber, pageSize) {
      this.pagination.current = pageNumber
      this.pagination.pageSize = pageSize
      await this.getMagazineList(this.titleValue, pageNumber)
    },
    //获取删除的id
    onSelectChange(selectedRowKeys) {
      // //选择的列表的id
      this.user_id = selectedRowKeys
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    //删除按钮
    async delMagazine() {
      const ids = this.user_id
      let list = [];
      ids.forEach(item => {
        this.MagazineTable.rows.some(item2 => {
          if (item2.id === item && item2.recommendState) {
            list.push(item2)
          }
          return item2.id === item
        })
      })
      if (list.length > 0) {
        this.$message.warning('包含已推广数据，无法删除')
        return
      }
      const delId = await delMagazines(ids)
      if (delId.code === 0) {
        await this.getMagazineList(this.titleValue, this.pagination.current)
        this.$message.success("删除成功")
        this.user_id = ''
      } else {
        this.$message.error('出错请联系管理员！' + delId.message)
      }
      this.selectedRowKeys = []
    },
    // 是否发布
    async isPublish(number){
      // 如果包含推广的数据 不允许下架
      const ids = this.user_id
      let list = [];
      ids.forEach(item => {
        this.MagazineTable.rows.some(item2 => {
          if (item2.id === item && item2.recommendState) {
            list.push(item2)
          }
          return item2.id === item
        })
      })
      if (list.length > 0 && number === 1) {
        this.$message.warning('包含已推广数据，无法下架')
        return
      }
      const response = await putBatch(number,ids)
      if (response.code === 0) {
        await this.getMagazineList()
        this.$message.success("操作成功")
        this.user_id = ''
        this.selectedRowKeys = []
      } else {
        this.$message.error('出错请联系管理员！' + response.message)
      }
    },
    // //点击添加按钮
    // add() {
    //   this.visible = true;
    // },
    //添加确认、取消按钮
    async addOk() {
      this.spinning = true
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          // this.AddForm.show_date = moment(this.AddForm.show_date).format("YYYY-MM")
          this.AddForm.show_date = this.AddFormShowDate
          const Upload = await postUpload(this.AddForm)
          if (Upload.code === 0) {
            //刷新列表
            await this.getMagazineList()
            //清空输入框
            this.$refs.addItem.resetFields();
            //关闭对话框
            this.visible = false;
            this.$message.success('新增成功');
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
      this.spinning = false
    },
    // 提交并继续
    async addOkAndkeep() {
      this.spinning = true
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          // this.AddForm.show_date = moment(this.AddForm.show_date).format("YYYY-MM")
          // this.AddForm.show_date = this.AddFormShowDate
          let data = {
            author:this.AddForm.author,
            describe:this.AddForm.describe,
            image:this.AddForm.image,
            name:this.AddForm.name,
            remark:this.AddForm.remark,
            title:this.AddForm.title,
            url:this.AddForm.url,
            show_date: this.AddFormShowDate,
            is_show:0  //默认上架
          }
          const Upload = await postUpload(data)
          if (Upload.code === 0) {
            //刷新列表
            await this.getMagazineList()
            this.AddForm.remark = ''
            this.AddForm.url = ''
            this.AddForm.author = ''
            this.AddForm.describe = ''
            this.AddForm.title = ''
            this.$message.success('新增成功');
            //清空输入框
            // this.$refs.addItem.resetFields();
            //关闭对话框
            // this.visible = false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
      this.spinning = false
    },
    handleCancel() {
      this.$refs.addItem.resetFields();
      this.visible = false;
    },
    //修改按钮
    async updateOpen(ref) {
      // this.UpdateForm = record
      // this.UpdateForm = JSON.parse(JSON.stringify(record))
      const editId = await getMagazineContent(ref)
      if (editId.code === 0) {
        let UpdateForm = editId.data
        this.UpdateFormShowDate = UpdateForm.show_date //显示样式2023年2月刊
        this.imageStyleType = '其他'
        let show_date = UpdateForm.show_date.slice(0, -1)
        UpdateForm.show_date = this.timeCycle(show_date) //时间控件2023-02
        this.UpdateForm = UpdateForm
        this.Update = true;
      } else {
        this.$message.error('获取接口失败' + editId.message);
      }
    },
    //时间转换
    timeCycle(date){
     // 2023年2月，2023年12月，
    //  判断 字符串为7位，还是8位
      let year;
      let mouth;
      if(date.length === 7){ // 2023年2月
        year = date.slice(0,4)
        mouth = date.slice(5,6)
        return year+'-'+'0'+mouth
      }else if(date.length === 8){  //2023年12月
        year = date.slice(0,4)
        mouth = date.slice(5,7)
        return year + '-' + mouth
      }
    },

    // 修改确认按钮
    editOk() {
      this.spinning = true
      this.$refs.editItem.validate(async valid => {
        if (valid) {
          let updateForm = this.UpdateForm
          // updateForm.show_date = moment(updateForm.show_date).format("YYYY-MM")
          updateForm.show_date = this.UpdateFormShowDate
          const EditItem = await putEdit(updateForm)
          if (EditItem.code === 0) {
            await this.getMagazineList()
            this.$refs.editItem.resetFields();
            this.$message.success('修改成功');
          } else {
            this.$message.error('修改失败' + EditItem.message);
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
      this.Update = false;
      this.spinning = false
    },
    // 上传图片
    async upload() {
      let inputDOM = this.$refs.upload_img.files[0];
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(inputDOM) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (inputDOM.size > 1048576) {
            this.$message.warning('图片大小不能超过1M')
            return
          }
          if (image.width > 500) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          const response = await update(inputDOM)
          if (response.code === 0) {
            this.$set(this.AddForm, 'image', response.data.url)
            this.$refs.upload_img.value = null
            this.$message.success('上传成功')
          } else {
            this.$message.warning('上传失败' + response.message)
          }
        })
      })
      // console.log(inputDOM)
      // return
      // if (inputDOM.size < 2086666.24) {
      //
      // } else {
      //   this.$message.warning('仅上传2M内图片')
      // }
    },
    // 修改图片
    async editImg() {
      let inputDOM = this.$refs.editImg.files[0];
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(inputDOM) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (inputDOM.size > 1048576) {
            this.$message.warning('图片大小不能超过1M')
            return
          }
          if (image.width > 500) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          const EditImg = await update(inputDOM)
          if (EditImg.code === 0) {
            this.$set(this.UpdateForm,'image',EditImg.data.url)
            this.$refs.editImg.value = null
            // this.UpdateForm.image = EditImg.data.url
            this.$message.success('修改成功')
          } else {
            this.$message.warning('仅上传1M内图片' + EditImg.message)
          }
        })
      })
    },
    editClose() { // 修改关闭方法
      this.Update = false
    },

    // 预览弹框
    preview(row) {
      this.PreviewForm = row
      this.Preview = true;
    },
    async topChange(id, is_top) {
      this.spinning = true
      const data = {
        id: id,
        is_top: is_top
      }
      const response = await putMagazine(data);
      //判断是否成功
      if (response.code === 0) {
        //重新获取列表，刷新列表
        await this.getMagazineList()
        this.$message.success('操作成功')
      } else {
        this.$message.warning('修改错误' + response.message)
      }
      this.spinning = false
    },

    //显示与隐藏
    async change_show(id, is_show) {
      this.spinning = true
      /**
       * is_show:0,1 0显示 1隐藏
       * @type {{id, is_show}}
       */

      const data = {
        id: id,
        is_show: is_show
      }
      //执行显示与隐藏
      const response = await putMagazine(data);
      //判断是否成功
      if (response.code === 0) {
        //重新获取列表，刷新列表
        await this.getMagazineList()
        if (is_show === 0) {
          this.$message.success('上架成功')
        } else {
          this.$message.success('下架成功')
        }
      } else {
        this.$message.warning('修改错误' + response.message)
      }
      this.spinning = false
    },


    promotionsClose() {
      this.promotionsVisible = false
      this.appData = []
      // this.newAppData = []
      this.promotionId = null
      this.subContentType = null
      this.getMagazineList()
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "COMMUNITY",//一级内容
            columnCode: row.code,//栏目
            subContentType: "MAGAZINE"//子级内容
          }
        ]
        await this.postAppSwitch(data, row)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, 'app', row)
      }
    },

    async delAppSwitch(id, code, type, row) {
      let data = {
        code: code,
        type: type
      }
      const response = await delAppPromotion(id, data)
      if (response.code === 0) {
        //更新数据
        if (type === 'app') {
          this.appData.forEach((a, index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)

            }
          })
        }
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postAppSwitch(data, row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)

          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async communityListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = {
          type: "MAGAZINE",//内容类型
          contentId: this.promotionId,//内容id
          isOpen: 1, //是否启用：0：否 1：是
          isTop: 0
        }
        await this.postcommunitySwitch(data, row)
      } else { //关闭推广
        // console.log(this.promotionId, this.communityCode, 'community', row)
        await this.delAppSwitch(this.promotionId, "MAGAZINE", 'community', row)
      }
    },
    async postcommunitySwitch(data) {
      const response = await postcommunityPromotion(data)
      if (response.code === 0) {
        // //更新数据
        // this.appData.forEach((a,index) => {
        //   if (a.code === row.code) {
        //     this.$set(this.appData, index, a)
        //     return
        //   }
        // })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async promotions(list) {
      this.promotionId = list.id
      this.subContentType = list.type
      //获取栏目
      this.getColumnList(list.id)
      //获取社区
      this.getcommunityList(list.id)
      // 获取新版app
      this.getNewAppList(list.id)

      //   // 小程序推广回显  
      //   const data = {
      //   contentId:this.promotionId,
      //   contentType:'DOCUMENT',
      //   // subContentType:this.subContentType,
      // }
      // const response = await getminaRecommend(data)
      // if (response.code === 0) {
      //   if(response.data[0] && response.data[0].code === "YUN_ICU"){
      //      this.uniAppData[0].is_open = true
      //   }else{
      //     this.uniAppData[0].is_open = false
      //   }
      // } else {
      //   this.$message.warning(response.message)
      // }

      this.promotionsVisible = true
    },

    // 获取新版app
    async getNewAppList(id) {
      const data = {
        contentId:this.promotionId,
        contentType:'MAGAZINE',
        // subContentType:this.subContentType,
      }
     const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.newAppData[0].is_open = true
        }else{
          this.newAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'MAGAZINE',
          // subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'MAGAZINE',
          // subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    async getcommunityList(id) {
      const response = await getcommunityPromotion(id,'MAGAZINE')
      if (response.code === 0) {
        if (response.data.length && response.data[0].isOpen) {
          this.communityData[0].is_open = true
          this.communityCode = response.data[0].code
        } else {
          this.communityData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a, index) => {
          a.is_open = columnList.some(b => {
            return b.code === a.code
          })
          this.$set(app, index, a)
        })
      } else {
        this.$message.warning(response.message)
      }
    },


  },
}
</script>

<style lang="scss" scoped>
.magazine {
  background-color: whitesmoke;
}

.ant-tabs-bar {
  margin: 0 !important;
  border: none !important;
}

.btn {
  border-color: white !important;
  border-radius: 2px !important;
}

.searchTable {
  background-color: white;
  height: 70px;
  padding: 15px;
  border-bottom: 10px solid #F0F2F5;
}

.magazine_list {
  background-color: white;
  padding: 15px;
}

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}

.pop {
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 300px;
  width: calc(100% - 600px);
  height: calc(100% - 200px);
  z-index: 2
}

.upload_img {
  width: 120px;
  height: 120px;
  opacity: 0;
}

.upload {
  margin-top: 8px;
  width: 90px;
  height: 25px;
  background-image: url("../../assets/addImage.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.upload_image {
  border: #DDDDDD 2px dashed;
  width: 132px;
  height: 185px;
  float: left;
  margin-top: 10px;
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
</style>
